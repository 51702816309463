@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&display=swap');

body {
  font-family: "Pixelify Sans", sans-serif;
}

.Background-1 {
  background-color: #e8e8e8;
  opacity: 1;
  background: linear-gradient(135deg, #bdbdbd55 25%, transparent 25%) -5px 0/ 10px 10px, 
              linear-gradient(225deg, #bdbdbd 25%, transparent 25%) -5px 0/ 10px 10px, 
              linear-gradient(315deg, #bdbdbd55 25%, transparent 25%) 0px 0/ 10px 10px, 
              linear-gradient(45deg, #bdbdbd 25%, #e8e8e8 25%) 0px 0/ 10px 10px;
}

.Background-2 {
  background-color: #d565cf;
  opacity: 1;
  background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #d565cf 10px ), 
                     repeating-linear-gradient( #902a8a55, #902a8a );
}

.Background-3 {
  background-color: #00ff34;
  opacity: 1;
  background-image:   linear-gradient(30deg, #c0ffd0 12%, transparent 12.5%, transparent 87%, #c0ffd0 87.5%, #c0ffd0), 
                      linear-gradient(150deg, #c0ffd0 12%, transparent 12.5%, transparent 87%, #c0ffd0 87.5%, #c0ffd0), 
                      linear-gradient(30deg, #c0ffd0 12%, transparent 12.5%, transparent 87%, #c0ffd0 87.5%, #c0ffd0), 
                      linear-gradient(150deg, #c0ffd0 12%, transparent 12.5%, transparent 87%, #c0ffd0 87.5%, #c0ffd0), 
                      linear-gradient(60deg, #c0ffd077 25%, transparent 25.5%, transparent 75%, #c0ffd077 75%, #c0ffd077), 
                      linear-gradient(60deg, #c0ffd077 25%, transparent 25.5%, transparent 75%, #c0ffd077 75%, #c0ffd077);
  background-size: 20px 35px;
  background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
}

.Background-4 {
  background-color: #5898ff;
  opacity: 1;
  background-image:  radial-gradient(#7f0088 0.5px, transparent 0.5px), radial-gradient(#7f0088 0.5px, #5898ff 0.5px);
  background-size: 20px 20px;
  background-position: 0 0,10px 10px;
}

.Background-5 {
  background-color: #bef9ff;
  opacity: 1;
  background-image: radial-gradient(circle at center center, #2aebf9, #bef9ff), 
                    repeating-radial-gradient(circle at center center, #2aebf9, #2aebf9, 10px, transparent 20px, transparent 10px);
  background-blend-mode: multiply;
}

.Background-6 {
  background-color: #5e5603;
  opacity: 1;
  background-image:   linear-gradient(135deg, #ffdb00 25%, transparent 25%), 
                      linear-gradient(225deg, #ffdb00 25%, transparent 25%), 
                      linear-gradient(45deg, #ffdb00 25%, transparent 25%), 
                      linear-gradient(315deg, #ffdb00 25%, #5e5603 25%);
  background-position:  10px 0, 10px 0, 0 0, 0 0;
  background-size: 20px 20px;
  background-repeat: repeat;
}

.Background-7 {
  background-color: #00e8ff;
  opacity: 1;
  background-size: 10px 10px;
  background-image: repeating-linear-gradient(45deg, #0018ce 0, #0018ce 1px, #00e8ff 0, #00e8ff 50%);
}

.Background-8 {
  background-color: #ff0045;
  opacity: 1;
  background-image:  repeating-linear-gradient(45deg, #cf007d 25%, transparent 25%, transparent 75%, #cf007d 75%, #cf007d), repeating-linear-gradient(45deg, #cf007d 25%, #ff0045 25%, #ff0045 75%, #cf007d 75%, #cf007d);
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
}

.Background-9 {
  background-color: #ffffff;
  opacity: 1;
  background-image: radial-gradient( ellipse farthest-corner at 10px 10px , #98b0e0, #98b0e0 50%, #ffffff 50%);
  background-size: 10px 10px;
}

.Background-10 {
  background-color: #8179db;
  opacity: 1;
  background-size: 20px 20px;
  background-image:  repeating-linear-gradient(0deg, #2bf0ea, #2bf0ea 1px, #8179db 1px, #8179db);
}

button {
  all: unset;
  cursor: pointer;
}

html, body, #root, #root > div, #app, #app>div {
  height: 100%
}

/* flex force next row trick https://tobiasahlin.com/blog/flexbox-break-to-new-row/ */
.break {
  flex-basis: 100%;
  height: 0;
}

.App {
  text-align: left;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  color: rgb(0, 0, 0);
}

.Popout-Container {
  position: absolute;
  margin: 2%;
  border-radius: 15px;
  border: 2px solid #D33D7A;
  background-color: #DFDFDF;
}

.Popout-Container-Mobile {
  position: absolute;
  margin: 2%;
  border-radius: 15px;
  border: 2px solid #D33D7A;
  background-color: #DFDFDF;
  z-index: 5;
  max-height: 80vh;
}

.Window-Title-Bar {
  display: flex;
  justify-content: right;
  background-color: #E8C2FF;
  border-bottom: 2px solid #D33D7A;
  border-radius: 15px 15px 0px 0px;
}

.Window-Close-Button {
  border: 2px solid #D33D7A;
  color: #D33D7A;
  background-color: #FEF0EF;
  margin: 6px 6px 4px 0;
  padding: 0 4px;
  border-radius: 6px;
}

.Window-Content-Area {
  padding: 0 16px 16px 16px;
}

.Window-Content-Area > h1 {
  margin: 16px;
}

.Window-Content-Area-Flow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  column-gap: 32px;
  max-width: 50vw;
  width: 50vw;
}

.Window-Content-Area-Flow-Mobile {
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  column-gap: 32px;
}


.Window-Content-Area-Main {
  margin: 16px;
}

.Window-Content-Area-Main-Mobile {
  margin: 16px;
}


.Window-Content-Area-Main > p {
  margin: 0;
  font-size: 16pt;
}

.Window-Content-Area-Main-Mobile > p {
  font-size: 12pt;
}


.About-Me {
  top: 0;
  right: 2vw;
}

.About-Me-Mobile {
  top: 0;
  left: 0;
}

.About-Me-Image {
  height: 500px;
}

.Projects {
  bottom: 10%;
  left: 0;
  max-width: 30vw;
}

.Projects-Mobile {
  bottom: 10%;
  left: 0;
}

.Contact {
  top: 10%;
  left: 15%;
}

.Contact-Mobile {
  top: 10vh;
  left: 5vw;
}

.Clickable-Icon > img {
  filter:drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.3));
}

.Clickable-Icon > img:active {
  filter:drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.2));
}

.Clickable-Icon {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 0 0 2em 0;
}

.Clickable-Icon > p {
  margin: 0.25em 0 0 0;
  font-size: 18pt;
  color: black;
}

.Icons-Container {
  display: flex;
  flex-flow: column wrap;
  position: absolute;
  top: 2em;
  left: 2em;
}

.Bottom-Bar {
  width: 100%;
  height: 36px;
  padding: 3px;
  border-top: 1px solid #FFFFFF;
  background: #C0C0C0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  justify-items: right;
}

.Bottom-Bar > button {
  padding: 0;
  margin: 0 4px;
}

.Clock-Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  margin: 0 4px;
  border-top: 2px solid #808080;
  border-right: 2px solid #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
  border-left: 2px solid #808080;
  height: 32px;
}

.Bottom-Bar-Border-Wrapper {
  border-top: 1px solid #DFDFDF;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.Bottom-Bar-Left {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.Bottom-Bar-Left > button {
  height: 36px;
}

.Bottom-Bar-Pipe {
  border-left: 2px solid #808080;
  border-right: 2px solid white;
  height: 32px;
  margin-left: 6px;
}

.Bottom-Bar-Seperator-Wrapper {
  border-right: 1px solid #808080;
  border-left: 1px solid white;
  border-bottom: 2px solid #808080;
  border-top: 2px solid white;
  height: 60%;
  margin-left: 6px;
}

.Bottom-Bar-Seperator {
  border-left: 1px solid #C0C0C0;
}
  
@-ms-keyframes spin {
  from { -ms-transform: rotate(0deg); }
  to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from { 
    transform: rotate(0deg); 
  }
  to { 
    transform: rotate(360deg); 
  }
}

.Bottom-Bar-Icon {
  margin: 0 8px;
}

.Bottom-Bar-Icon:hover {
  animation: spin infinite 1s linear;
  cursor: grab;
}

.React-Img {
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.5));
}


.Bottom-Bar-Right {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}


.Bottom-Bar-Minimized-Wrapper {
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  margin-left: 4px;
}

.Bottom-Bar-Minimized-Wrapper:active {
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid black;
  border-top: 1px solid black;
}

.Bottom-Bar-Minimized {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-items: left;
  border-top: 2px solid #DFDFDF;
  border-left: 2px solid #DFDFDF;
  border-right: 2px solid #808080;
  border-bottom: 2px solid #808080;
  height: 30px;
  padding: 0 80px 0 4px;
}

.Bottom-Bar-Minimized-Mobile {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-items: left;
  border-top: 2px solid #DFDFDF;
  border-left: 2px solid #DFDFDF;
  border-right: 2px solid #808080;
  border-bottom: 2px solid #808080;
  height: 30px;
  padding: 0 8px 0 8px;
}

.Bottom-Bar-Minimized:active {
  border-bottom: 2px solid #DFDFDF;
  border-right: 2px solid #DFDFDF;
  border-left: 2px solid #808080;
  border-top: 2px solid #808080;
}

.Bottom-Bar-Minimized > img {
  height: 28px;
}

.Bottom-Bar-Minimized > p {
  margin: 0 0 0 8px;
}

.No-Style-a {
  color: inherit;
  text-decoration: none;
}

.Start-Button-Wrapper {
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.Start-Button-Wrapper:active {
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid black;
  border-top: 1px solid black;
}

.Start-Button {
  border-top: 2px solid #DFDFDF;
  border-left: 2px solid #DFDFDF;
  border-right: 2px solid #808080;
  border-bottom: 2px solid #808080;
}

.Start-Button:active {
  border-bottom: 2px solid #DFDFDF;
  border-right: 2px solid #DFDFDF;
  border-left: 2px solid #808080;
  border-top: 2px solid #808080;
}

img, #banner-img{
  vertical-align: top;
  }